import Vue from 'vue';
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'



//FONTAWESOME
library.add(fas);

Vue.component('font-awesome-icon', FontAwesomeIcon);
