import components from '@/commons/components'
import merge from 'element-ui/src/utils/merge';
import PzLoading from '@/commons/loading'
import Loading from "element-ui/packages/loading";

const install = function (Vue) {
  components.forEach(component => {
    Vue.component(component.name, component);
  });
    Vue.use(PzLoading.directive);
    Vue.prototype.$pzLoading = Loading.service;
};
/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default merge(
  {
    version: '1.0.0',
    install,
  }, components);
