<template>
  <transition
    name="el-loading-fade"
    @after-leave="handleAfterLeave"
  >
    <div
      v-show="visible"
      class="el-loading-mask"
      :style="{ backgroundColor: background || '' }"
      :class="[customClass, { 'is-fullscreen': fullscreen }]"
    >
      <div
        :class="'pz-loader-svg'"
      />
      <p
        v-if="text"
        class="el-loading-text"
      >
        {{ text }}
      </p>
    </div>
  </transition>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {gsap} from 'gsap';

export default {
    name: 'PzLoading',
    data() {
        return {
            text: null,
            spinner: null,
            background: null,
            fullscreen: true,
            visible: false,
            customClass: ''
        };
    },
    computed: {
        image() {
            return require("@/assets/images/loader/" + this.spinner);
        }
    }
    ,
    methods: {
        handleAfterLeave() {
            this.$emit('after-leave');
        },
        setText(text) {
            this.text = text;
        },

    }
};
</script>
<style lang="scss">
.loading-svg {
    html,
    body {
        height: 100%;
    }

    body {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }

    svg {
        width: 100%;
        max-width: 800px;
    }
}
</style>