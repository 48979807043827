import Cookies from 'js-cookie'
import {isEmpty} from "element-ui/src/utils/util";
import settings from '@/settings/settings'
import {capitalize} from "@/commons/utils/functions";

const currentStorage = settings.storage;

function excuteStorageMethod(action, params) {
    action = action.toLowerCase() + capitalize(currentStorage);
    return storage[action].apply(null, params);
}


export function clearAll() {
    sessionStorage.clear();
}

export function saveInLocal(key, data) {
    excuteStorageMethod('set', [key, data])
}

export function removeFromLocal(key) {
    excuteStorageMethod('delete', [key])

}

export function getFromLocal(key) {
    try {
        if (!isEmpty(key)) {
            return excuteStorageMethod('get', [key])
        }
    } catch (e) {
        excuteStorageMethod('delete', [key])
        return null
    }
}

///////
export const storage = {
// eslint-disable-next-line no-unused-vars
    setLocalStorage: function (key, data) {
        localStorage.setItem(key, JSON.stringify(data));
    },
    // eslint-disable-next-line no-unused-vars
    deleteLocalStorage: function (key) {
        localStorage.removeItem(key);
    },
    getLocalStorage: function (key) {
        if (localStorage.getItem(key)) {
            return JSON.parse(localStorage.getItem(key));
        }
        return null;
    },


// eslint-disable-next-line no-unused-vars
    setCookies: function (key, data) {
        return Cookies.set(key, JSON.stringify(data));
    },

// eslint-disable-next-line no-unused-vars
    deleteCookies: function (key) {
        return Cookies.remove(key)
    },

// eslint-disable-next-line no-unused-vars
    getCookies: function (key) {
        if (Cookies.get(key)) {
            return JSON.parse(Cookies.get(key));
        }
        return null;
    },

}


