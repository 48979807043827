<template>
  <el-col
    :span="24"
    class="text-center pz-large-margin"
  >
    <div class="pz-title">
      <slot name="title" />
    </div>
    <div class="pz-subtitle">
      <slot name="sub_title" />
    </div>
  </el-col>
</template>

<script>

    import {mapGetters} from "vuex";

    export default {
        name: "PzTitle",
        computed: {
            ...mapGetters([
                'header',
            ])
        },

    }
</script>

<style scoped>

</style>