<template>
  <el-row class="nav pz-banner-item">
    <el-col :span="24">
      <el-col
        :span="20"
        class="pz-banner-item_0"
      >
        <transition
          name="fade-transform"
          mode="out-in"
        >
          <label
            :key="header.title"
            class="text-uppercase pz-label-banner"
          >
            {{ header.title?header.title:'&#8203;' }}
          </label>
        </transition>
      </el-col>
      <el-col
        :span="1"
        class="pz-banner-item_1  hidden-xs"
      />
      <el-col
        :span="1"
        class="pz-banner-item_2 hidden-xs"
      />
      <el-col
        :span="1"
        class="pz-banner-item_3 hidden-xs"
      />
      <el-col
        :span="1"
        class="pz-banner-item_4 hidden-xs"
      />
    </el-col>
  </el-row>
</template>


<script>
    import {mapGetters} from "vuex";

    export default {
        name: 'PzBanner',
        data() {
            return {};
        },
        computed: {
            ...mapGetters([
                'header',
            ])
        },
        mounted() {
        },

        methods: {},

    }


</script>


